<template>
  <div id="filterForm" >
    <v-container id="searchForm__container" class="container container--fluid">
      <!-- <v-expand-transition> -->
      <!-- <div v-show="expand" no-gutters> -->
      <v-row>
        <v-col cols="3" v-click-outside="clickedOut">
          <div id="searchForm__autocomplete">
            <div
              v-for="c in choosenCompanies"
              v-bind:key="c.id"
              id="searchForm__autocomplete--chip"
            >
              {{ c.name }}
              <v-icon
                dark
                left
                id="searchForm__autocomplete--icon"
                @click="removeCompany(c)"
              >
                mdi-minus-circle
              </v-icon>
            </div>
            <div id="searchForm__autocomplete--chip">
              <input
                type="text"
                id="searchForm__autocomplete--text_field"
                autofocus
                placeholder="Select Company"
                v-model="company"
              />
       
            </div>
          </div>
          <div>
                    <v-progress-circular  size="22" v-if="company_working"
                    width= 2
      indeterminate
      color="primary"
    ></v-progress-circular>
            <ul id="searchForm__list">
              <li
                v-for="c in companies"
                v-bind:key="c.id"
                @click="chooseCompany(c)"
              >
                {{ c.name }}
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="sender_email" label="Sender Email" />
        </v-col>

        <v-col cols="3" style="padding-top: 20px">
          <v-btn color="primary" small @click="searchData">Search</v-btn>
          <v-btn color="default" small @click="reset">Reset</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Autocomplete from "./Autocomplete.vue";
export default {
  name: "searchForm",
  data: () => ({
    selectedItem: 1,
    expand: false,
    switch1: false,
    companies: [],
    company_working: false,
    companyName: "",
    company: "",
    choosenCompanies: [],
    search_company_id: null,
    sender_email: "",
    loading:false,
  }),
  components: {
    Autocomplete,
  },
  props: ["formType", "stateName"],
  mounted() {
    let view = this;
    if (this.$utils.getCookies("advancefilter") != null) {
      view.expand = view.$utils.getCookies("advancefilter") === true;
      view.switch1 = view.$utils.getCookies("advancefilter") === true;
    }
  },
  computed: {},
  methods: {
    expandForm: function () {
      var view = this;
      view.expand = !view.expand;
      view.$emit("showButtons");
    },
    savefilterSetting() {
      var view = this;
      view.$utils.setCookies("advancefilter", view.switch1, view);
    },
    searchData() {
      let search_obj = {};
      if (this.search_company_id != "" || this.sender_email != "") {
        search_obj["search_company_id"] = this.search_company_id;
        search_obj["sender_email"] = this.sender_email;
        this.$emit("search", search_obj);
      }
    },
    reset: function () {
      var view = this;
      view.choosenCompanies = [];
      this.company = "";
      this.sender_email = "";
      view.$emit("reset");
    },
    chooseCompany: function (c) {
      var view = this,
        is_company = view.choosenCompanies.filter((o) => c.id === o.id);
      view.choosenCompanies = [];
      if (Array.isArray(is_company)) {
        if (is_company.length === 0) {
          view.choosenCompanies.push(c);
          view.companies = [];
          view.company = "";
          view.search_company_id = c.id;
        }
      }
    },
    clickedOut: function () {
      this.companies = [];
    },
    removeCompany: function (c) {
      var view = this;
      view.choosenCompanies = view.choosenCompanies.filter(
        (o) => c.id !== o.id
      );
      if (view.companies.length > 0) {
        view.companies.unshift(c);
      }
      view.search_company_id = "";
    },
  },
  watch: {
    company: function (o) {
      var view = this;
      if (o.length > 2 && !view.company_working) {
        view.companies = [];
        view.company_working = true;
        view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .post(view.$url("BASE_TEMP_PRODUCT") + "data/company", {
            get_company: o,
          })
          .then(function (res) {
            let data = res.data;
            for (var i = 0; i < data.length; i++) {
              view.companies.push({
                id: data[i].companyID,
                name: data[i].companyName,
              });
            }
            view.company_working = false;
            
          })
          .catch(function (e) {
            console.log(e);
            view.company_working = false;
          });
      }
    },
  },
};
</script>


<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top: 0px
#searchForm__container
  padding-top: 0px
</style>
