<template>

  <v-container id="regular-tables" fluid tag="section" v-if="current_active_module">

    <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      />
    <v-container class="grey lighten-5" id="product__container" style="padding-left:5px">
      <!-- <CompanyAutoComplete /> -->

      <filterForm
        @data="data"
        @search="search"
        @reset="reset"
        :formType="'APPROVESENDER'"
        @showButtons="showButtons"
      />
    </v-container>

    <DataTable
      :headers="headers"
      ref="datatable"
      :data="data"
      :item_key="item_key"
      :show_check_box="show_check_box"
      :totRecords="totRecords"
      :perpage="perpage"
      :isLoading="isLoading"
      :editItem="editItem"
      :get_data_api_url="get_data_api_url"
      :show_add_delete_buttons="show_add_delete_buttons"
      :search_company_id="search_company_id"
      :sender_email="sender_email"
      @editItem="editItem"
      @deleteItem="deleteItem"
      @opendialog="opendialog"
      :is_search = "is_search"
    />

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>Approve Sender Email</v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <CompanyAutoComplete
            :closecompanydialog="closecompanydialog"
            @selectcompany="selectcompany"
          />
          <span style="font-size: 13px; font-family: Roboto, sans-serif">
            {{ company_name }}</span
          >
          <span v-if="company_name">
          <v-text-field
            style="width: 80%"
            v-model="sender_email"
            label="Sender Domain"
          ></v-text-field>
          </span>
          <span v-else>
              <v-textarea
          outlined
          v-model="sender_email"
          label="Sender Domain"
        ></v-textarea>
          </span>
        </v-card-text>

        <v-card-actions>
          <v-btn small color="primary" class="mr-4" @click="addrecord"
            >Save</v-btn
          >
          <v-btn
            small
            color="blue-grey"
            class="ma-2 white--text"
            @click="closedialog"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DataTable from "./component/DataTable.vue";
import filterForm from "./component/filterForm.vue";
import CompanyAutoComplete from "./component/CompanyAutoComplete.vue";
import { mapGetters } from "vuex";

export default {
  components: {  Loading,DataTable, filterForm, CompanyAutoComplete },
  data() {
    return {
      endpoints: {
        GET_DATA_URL: null,
      },
      get_data_api_url: null,
      data: [],
      item_key: "id",
      show_check_box: true,
      totRecords: null,
      perpage: 50,
      isLoading: false,
      page: 1,
      headers: [
        {
          text: "Company Name",
          align: "start",
          value: "companyName",
          width: "20%",
          sortable: false,
          class: "v-data-table-header ",
        },
        {
          text: "Sender Email",
          value: "sender_email",
          sortable: false,
          width: "50%",
          class: "v-data-table-header",
        },
        {
          text: "Modified Date",
          value: "insert_update_date",
          sortable: false,
          width: "20%",
          class: "v-data-table-header",
        },

        {
          text: "Action",
          value: "action",
          sortable: false,

          class: "v-data-table-header",
        },
      ],
      showButtons: true,
      search_company_id: null,
      dialog: false,
      added_company_id: null,
      updatedialog: false,
      sender_email: '',
      update_item_id: "",
      company_name: "",
      show_add_delete_buttons: true,
      closecompanydialog: false,
      fullPage:true,
      isLoading:true,
      is_search:false
    };
  },
  mounted() {
    var view = this;
    view.get_data_api_url = view.$url("COMPANY_APPROVE_LIST");
    view.endpoints["GET_DATA_URL"] = view.$url("COMPANY_APPROVE_LIST");
    view.getdata(view.endpoints["GET_DATA_URL"]);
  },
  watch: {
    sender_email: function (val) {
      this.closecompanydialog = false;
    },
  },
  computed: {
    ...mapGetters({
      current_active_module: "Rawdata/getCurrentActiveModule",
       userdata: 'Rawdata/getUserData',
    })
  },
  methods: {
    getdata: function (api_url) {
      var view = this;
      view.isLoading = true;
      view.$utils
        .getData(api_url, view, view.page)
        .then((res) => {
          this.data = res.data.payload.data;
          this.totRecords = res.data.payload.totaldata;
          view.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          view.isLoading = false;
        });
    },
    deleterecord: function () {
      var view = this;
      view.isLoading = true;
      let api_url = view.endpoints["GET_DATA_URL"] + "?id=";
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will remove record.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, remove it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            view.$utils
              .deleteData(api_url, view)
              .then((res) => {
                view.isLoading = false;
                view.getdata(view.endpoints["GET_DATA_URL"]);
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) deleted successfully",
                  text: "Record deleted successfully ",
                });
              })
              .catch((error) => {
                console.error(error);
                view.isLoading = false;
              });
          } else {
          }
        });
    },
    addrecord: function () {
      var view = this;
      if (view.company_id != undefined || view.sender_email != "") {
        view.isLoading = true;
        let api_url = "";
        if (view.update_item_id != "") {
          api_url =
            view.endpoints["GET_DATA_URL"] + "?id=" + view.update_item_id;
        } else {
          api_url = view.endpoints["GET_DATA_URL"];
        }
        let data = {
          company_id: view.added_company_id,
          sender_email: view.sender_email,
        };
        console.log("posted_data");
        console.log(data);
        view.$utils
          .postData(api_url, view, data)
          .then((res) => {
            view.isLoading = false;
            view.is_search = false;
            view.update_item_id = "";
            view.added_company_id = "";
            view.company_name = "";
            view.sender_email = "";
            view.dialog = false;
            view.closecompanydialog = true;
            let search_string = ''
            let ep = ''
            if(view.search_company_id!= null && view.search_company_id != '' ){
               search_string = "company_id=" + view.search_company_id;
              ep = view.endpoints["GET_DATA_URL"] + "?" + search_string;
            }else{
              ep = view.endpoints["GET_DATA_URL"]
            }
            view.getdata(ep);
            this.$refs.datatable.page = 1;

            this.$swal.fire({
              icon: "success",
              title: "Record(s) added/updated successfully",
              text: "Record added/updated successfully ",
            });
            console.log(res.data);
          })
          .catch((error) => {
            console.error(error.response.data.error);
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: error.response.data.error,
            });
            view.isLoading = false;
          });
      }
    },
    opendialog: function (value) {
      var view = this;
      if (value) {
        view.update_item_id = "";
        view.sender_email = "";
        view.company_name = "";
        view.dialog = true;
        view.closecompanydialog = true;
        view.is_search = false;
      }
    },
    closedialog: function () {
      var view = this;
      view.is_search = false;
      this.closecompanydialog = true;
      view.isLoading = false;
      view.update_item_id = "";
      view.added_company_id = "";
      view.dialog = false;
      view.sender_email = "";
      view.company_name = "";
    },
    search: function (value) {
      console.log(value);
      if (value != "") {
        var view = this;
        let base_url = view.endpoints["GET_DATA_URL"];
        let company_id = "";
        if (value["search_company_id"] !== null ||value["sender_email"] !==null )
        {
          this.is_search = true
          company_id = (value["search_company_id"] != null)?value["search_company_id"] :"";
          view.search_company_id = company_id
          view.sender_email = value["sender_email"] !== null?value["sender_email"] :"";
        }


        let search_string =
          "company_id=" + company_id + "&sender_email=" + value["sender_email"];

        let ep = base_url + "?" + search_string;
        if(this.search_company_id || this.sender_email)
          view.getdata(ep);


      }
    },
    reset: function (value) {
      var view = this;
      let base_url = view.endpoints["GET_DATA_URL"];
      view.isLoading = false
      view.search_company_id = ''
      view.sender_email=''
      //  let search_string = "company_id="+value
      //  let ep = base_url+"?"+search_string
      //view.page = 1;
      this.$refs.datatable.page = 1;
      view.getdata(base_url);
      view.is_search = false
    },
    selectcompany: function (value) {
      var view = this;
      view.added_company_id = value;
    },
    editItem(value) {
      var view = this;
      view.update_item_id = value.id;
      view.added_company_id = value.company_id;
      view.sender_email = value.sender_email;
      view.company_name = value.companyName;
      view.dialog = true;
    },
    deleteItem(id) {
      var view = this;

      let api_url = view.endpoints["GET_DATA_URL"] + "?id=" + id;
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will remove record.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, remove it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            view.isLoading = true;
            view.$utils
              .deleteData(api_url, view)
              .then((res) => {
                view.isLoading = false;
                view.is_search = false;
                view.getdata(view.endpoints["GET_DATA_URL"]);
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) deleted successfully",
                  text: "Record deleted successfully ",
                });
              })
              .catch((error) => {
                console.error(error);
                view.isLoading = false;
              });
          }
        });
    },
  },
};
</script>
