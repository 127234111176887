<template>
  <div id="companyAutocomplete">
    <v-container id="searchForm__container">
      <v-row>
        <v-col cols="10" v-click-outside="clickedOut">
          <div id="searchForm__autocomplete">
           
             
            <div
              v-for="c in choosenCompanies"
              v-bind:key="c.id"
              id="searchForm__autocomplete--chip"
            >
              {{ c.name }}
              <v-icon
                dark
                left
                id="searchForm__autocomplete--icon"
                @click="removeCompany(c)"
              >
                mdi-minus-circle
              </v-icon>
            </div>
            <div
              id="searchForm__autocomplete--chip"
              style="padding: 0px; margin-left: -10px"
            >
              <input
                type="text"
                id="searchForm__autocomplete--text_field"
                autofocus
                placeholder="Choose Company"
                v-model="company"
              />
               <v-progress-circular  size="25" v-if="company_working"
      indeterminate
      color="primary"
    ></v-progress-circular>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <ul id="searchForm__list" style="position: relative; width: 100%">
        <li
          style="
            padding: 3px;
            font-size: 13px;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
          "
          v-for="c in companies"
          v-bind:key="c.id"
          @click="selectcompany(c)"
        >
          {{ c.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "searchForm",
  props: ["closecompanydialog"],
  data: () => ({
    companies: [],
    company_working: false,
    companyName: "",
    company: "",
    choosenCompanies: [],
  }),
  methods: {
    selectcompany: function (c) {
      var view = this,
        is_company = view.choosenCompanies.filter((o) => c.id === o.id);
      view.choosenCompanies = [];
      console.log(c);
      if (Array.isArray(is_company)) {
        if (is_company.length === 0) {
          view.choosenCompanies.push(c);
          view.companies = [];
          view.company = "";
          view.search_company_id = c.id;
          view.closecompanydialog = false;
          // let compobj = {}
          // compobj["company_id"] =  c.id
          // compobj["name"] = c.name

          view.$emit("selectcompany", c.id);
        }
      }
    },
    clickedOut: function () {
      this.companies = [];
      this.company = "";
    },
    removeCompany: function (c) {
      console.log(c);
      var view = this;
      view.closecompanydialog = true;
      view.choosenCompanies = view.choosenCompanies.filter(
        (o) => c.id !== o.id
      );
      if (view.companies.length > 0) {
        view.companies.unshift(c);
      }
    },
  },
  watch: {
    closecompanydialog: function (val) {
      if (val) {
        this.choosenCompanies = [];
      }
    },
    company: function (o) {
      var view = this;
      if (o.length > 2 && !view.company_working) {
        view.companies = [];
        view.company_working = true;
        view
          .$fetch({
            requiresAuth: true,
            operation: "add",
            vueScope: view,
          })
          .post(view.$url("BASE_TEMP_PRODUCT") + "data/company", {
            get_company: o,
          })
          .then(function (res) {
            let data = res.data;
            for (var i = 0; i < data.length; i++) {
              view.companies.push({
                id: data[i].companyID,
                name: data[i].companyName,
              });
            }
            view.company_working = false;
          })
          .catch(function (e) {
            console.log(e);
            view.company_working = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="sass">
@import './../../../sass/searchform'
#searchForm__container div.col
  padding-top: 1px
  padding-bottom: 1px
#searchForm__container div.row
  margin-top: 0px
#searchForm__container
  padding-top: 0px
</style>
